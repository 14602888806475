import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import * as React from 'react';

const Copyright = () => (
    <Typography variant="body2" color="text.secondary" align="center">
        <Link color="inherit" href="/">
            
        </Link>{' '}
        {new Date().getFullYear()}
    </Typography>
)

export const Footer = () => (
    <footer>
        <Copyright />
    </footer>
);