import './App.css';

import * as React from 'react';

// import { Button } from '@mui/material';
// import CssBaseline from '@mui/material/CssBaseline';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
// import Toolbar from '@mui/material/Toolbar';
// import Paper from '@mui/material/Paper';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import Link from '@mui/material/Link';
// import Typography from '@mui/material/Typography';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Footer } from './footer';
import { Header } from './header'

// -----------------------------------------------------------------------------

function App() {
    return (
        <div className="App">
            <Header />

            <body>
                
            </body>
            
            <Footer />
            
        </div>
    );
}

export default App;
